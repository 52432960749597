





























































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Pagina extends Vue {
  private win = window;
  private servicios: any = [];
  private selectedCategoria: any = null;
  private verModal: boolean = false;

  private mounted() {
    document.title =
      "Servicios Municipales - Ilustre Municipalidad de Punta Arenas";
    this.getServicios();
  }

  private getServicios() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("servicios/general?tipo=servicios")
      .then((res: any) => {
        let base = process.env.VUE_APP_BASE_URL;
        this.servicios = res.data;
        for (let i = 0; i < this.servicios.length; i++) {
          this.servicios[i].foto = base + this.servicios[i].foto;
          for (let j = 0; j < this.servicios[i].servicios.length; j++) {
            this.servicios[i].servicios[j].foto =
              base + this.servicios[i].servicios[j].foto;
          }
        }
        if (this.$route.query.option && this.$route.query.option != "") {
          for (let i = 0; i < this.servicios.length; i++) {
            if (this.servicios[i].nombre_web == this.$route.query.option) {
              this.selectedCategoria = this.servicios[i];
              this.verModal = true;
            }
          }
        }
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
        // this.routerGo("Home");
      });
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }

  private abrirModal(item: any) {
    this.selectedCategoria = item;
    this.verModal = true;
  }
  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }

  private irPagina(nombreWeb: any) {
    if (nombreWeb == "contacto") {
      this.routerGo("Contacto");
    } else if (nombreWeb == "concejo-municipal" || nombreWeb == "concejo") {
      this.routerGo("Concejo");
    } else if (
      nombreWeb == "actas-del-concejo" ||
      nombreWeb == "actas-del-concejo-municipal"
    ) {
      this.routerGo("ActasConcejo");
    } else if (
      nombreWeb == "oficinas-y-telefonos-municipales" ||
      nombreWeb == "contactos"
    ) {
      this.routerGo("Telefonos");
    } else if (
      nombreWeb == "organigrama-municipal" ||
      nombreWeb == "organigrama"
    ) {
      this.routerGo("Organigrama");
    } else if (nombreWeb == "cosoc") {
      this.routerGo("Cosoc");
    } else if (nombreWeb == "informes-financieros") {
      this.routerGo("Informes");
    } else if (nombreWeb == "ordenanzas-municipales") {
      this.routerGo("Ordenanzas");
    } else if (nombreWeb == "ofertas-laborales") {
      this.routerGo("OfertasOmil");
    } else {
      if (nombreWeb && nombreWeb != "") {
        if (
          this.$router.currentRoute.name == "Pagina" &&
          this.$router.currentRoute.params.nombre &&
          this.$router.currentRoute.params.nombre == nombreWeb
        ) {
          this.$router.go(0);
        } else {
          if (this.$router.currentRoute.name == "Pagina") {
            if (
              this.$router.currentRoute.params.nombre &&
              this.$router.currentRoute.params.nombre != nombreWeb
            ) {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
              this.$router.go(0);
            } else {
              this.$router
                .push({ name: "Pagina", params: { nombre: nombreWeb } })
                .catch(err => {
                  console.log(err);
                });
            }
          } else {
            this.$router
              .push({ name: "Pagina", params: { nombre: nombreWeb } })
              .catch(err => {
                console.log(err);
              });
          }
        }
      }
    }
  }
}
